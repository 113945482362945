import * as React from 'react';

import Layout from 'components/Layout';

import Photos from 'domains/Photos';

const PhotosPage: React.FunctionComponent = () => (
  <Layout>
    <Photos />
  </Layout>
);

export default PhotosPage;
